<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="8">
        <v-card class="elevation-12">
          <v-toolbar color="default" dark flat>
            <v-toolbar-title>{{
              "You don't have access to this page" | trans
            }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <v-card-actions>
            <v-btn color="default" @click="goBack">{{
              "Go back" | trans
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "backoffice-unauthorized",
  components: {},
  data: () => ({
    pageTitle: "Unauthorized",
  }),
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    getOriginalRoute() {
      console.log(this.$route.query.redirect);
      return this.$route.query.redirect;
    },
  },
};
</script>
